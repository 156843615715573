@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sansita:wght@400;700&display=swap");
.flex {
  display: flex;
}
.flex.row {
  flex-direction: row;
}
.flex.column {
  flex-direction: column;
}
.flex.wrap {
  flex-wrap: wrap;
}
.flex.align-items-center {
  align-items: center;
}
.flex.justify-content-center {
  justify-content: center;
}

.snowflakes-canvas {
  pointer-events: none;
}

.container {
  margin: 100px auto 0 auto;
  width: 700px;
  height: fit-content;
  padding: 30px;
}
.container.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  box-sizing: border-box;
}

@media (max-width: 800px) {
  .container {
    width: 80%;
    height: auto;
  }
}
.flex-column {
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
}

.flex-grid {
  display: flex;
  flex-direction: row;
}

@media (max-width: 800px) {
  .flex-grid {
    display: block;
  }
}
.button-box {
  position: relative;
  width: 100%;
  height: 200px;
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

button, input {
  background: #bb97fa;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
}
button.secondary, input.secondary {
  background: #6f43ba;
  color: #e2e2e2;
}

.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  z-index: 9999;
}
.popup-wrapper .popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: fit-content;
  background: #bb97fa;
  padding: 24px;
  border-radius: 5px;
  overflow: hidden;
}
.popup-wrapper .popup h1 {
  position: absolute;
  font-size: 350px;
  transform: rotate(25deg);
  top: -120px;
  left: -45px;
  z-index: 1;
}
.popup-wrapper .popup p, .popup-wrapper .popup button {
  position: relative;
  z-index: 3;
}
.popup-wrapper .popup button {
  float: right;
}
@media screen and (max-width: 600px) {
  .popup-wrapper .popup {
    width: 80%;
  }
}

.present {
  position: relative;
  width: 200px;
}
.present.disabled {
  filter: grayscale(10);
}
.present .present-wrap {
  position: absolute;
  z-index: 2;
  height: calc(100% - 5px);
  width: 25px;
  background: #6f43ba;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0) !important;
}
.present .present-top {
  position: relative;
  z-index: 1;
  width: 140px;
  height: 30px;
  background: #bb97fa;
  margin: 0 auto;
  box-shadow: 0 2px 5px -2px #6f43ba;
  border: 5px solid #6f43ba;
  border-radius: 5px;
}
.present .present-top:after, .present .present-top:before {
  content: "";
  position: absolute;
  top: -35px;
  height: 25px;
  width: 35px;
  border: 5px solid #6f43ba;
  border-top-left-radius: 50px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.present .present-top:after {
  left: calc(50% + 20px);
  transform: translate(-50%, 0);
}
.present .present-top:before {
  left: calc(50% - 20px);
  transform: translate(-50%, 0) scale(-1, 1);
}
.present .present-box {
  position: relative;
  top: -5px;
  z-index: 0;
  width: 125px;
  height: 75px;
  background: #bb97fa;
  margin: 0 auto;
  border: 5px solid #6f43ba;
  border-radius: 0 0 5px 5px;
}
.present .present-number {
  z-index: 3;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0) rotate(0);
  width: 30px;
  height: 30px;
  background: #fff;
}
.present .present-number > span {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 4;
  transform: translate(-50%, -50%);
  font-weight: 700;
}
.present .present-number::after, .present .present-number::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 2;
}
.present .present-number::after {
  transform: rotate(33.333deg);
}
.present .present-number::before {
  transform: rotate(66.666deg);
}
.present .present-confetti {
  position: absolute;
  width: 125px;
  height: 10px;
  left: 50%;
  transform: translate(-50%, 0);
}
.present .present-confetti > div {
  position: absolute;
  background: #ff9900;
  border-radius: 5px;
  height: 10px;
  width: 10px;
  left: 50%;
  transform: translate(-50%, 0);
}

.user-image {
  position: relative;
  width: 100px;
  height: 100px;
  background: #bb97fa;
  color: #242428;
  border-radius: 50%;
  margin-bottom: 5px;
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.user-image .hat {
  position: absolute;
  font-size: 50px;
  top: 50px;
  color: #e2e2e2;
}
.user-image img {
  width: 100%;
  height: 100%;
}

.calendar-days {
  list-style: none;
  row-gap: 70px;
  column-gap: 20px;
  justify-content: center;
}
.calendar-days li {
  flex-basis: 25%;
  background: #bb97fa;
  padding: 50px 25px;
  text-align: center;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: bold;
  border-radius: 5px;
  font-family: "Sansita", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  letter-spacing: 0.3px;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
  color: #e2e2e2;
  background: #1f1f22;
}

h1, h2 {
  font-family: "Sansita", sans-serif;
  margin: 10px;
}

h1 {
  font-size: 40px;
  font-weight: 600;
}

h2 {
  font-size: 30px;
  font-weight: 300;
}

p {
  color: #CBCACB;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 20px;
}

a {
  color: #bb97fa;
}

/*# sourceMappingURL=index.css.map */
